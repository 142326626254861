.form-group {
  @extend %form-group;

  .invalid-feedback {
    margin-top: $inner-indent / 2;
  }

  width: $default-input-width;

  &_full-width {
    width: 100%;
  }

  &_no-gap {
    margin: 0;
  }

  &_rel {
    position: relative;

    .password-visibility-toggler {
      top: 39px;
    }
  }

  &_extra-large-width {
    width: $extra-large-input-width;
  }

  &_extra-small-input-width1 {
    width: $extra-small-input-width1;

    &:not(:last-child) {
      margin-right: 14px !important;
    }
  }

  &_large-width {
    width: $large-input-width;
  }

  &_default-width-1 {
    width: $default-input-width-1;
  }

  &_default-width {
    width: $default-input-width !important;
  }

  &_small-width {
    width: $small-input-width;
  }

  &_extra-small-width {
    width: $extra-small-input-width !important;
  }

  &_row {
    @extend %form-group-row;
    width: unset;

    .invalid-feedback {
      width: 100%;
    }
  }

  &_control-prefix {
    position: relative;

    .form-group__control {
      padding-left: $form-control-prefix-width;
    }
  }

  &_flex-1 {
    flex: 1;
  }

  &_control-postfix {
    position: relative;

    .form-group__control {
      padding-right: $form-control-prefix-width;
    }
  }

  &_invalid {
    position: relative;
    margin-bottom: $inner-indent * 2 !important;
    label {
      color: $color-red-5;
    }

    .form-group__control,
    .form-group__text-control{
      border-color: $color-red-5 !important;
    }

    .totalfit-select button {
      border-color: $color-red-5 !important;

      & mat-icon {
        color: $color-red-5 !important;
      }
    }

    //.form-group__input-prefix {
    //  border-color: $color-red-5;
    //}
  }

  &__prefix {
    position: absolute;
    width: $form-control-prefix-width;
    height: $input-height;
    display: flex;
    align-items: center;
    left: $inner-indent / 2;
    bottom: 0;
  }

  &__postfix {
    position: absolute !important;
    width: $form-control-prefix-width;
    height: $input-height;
    display: flex;
    right: 0;
    bottom: 0;

    button {
      width: 100%;
      height: 100%;
    }
  }

  .align-center {
    align-items: center;
  }

  &__label {
    @extend %form-group-label;
  }

  &__control {
    @extend %form-group-control;
  }

  &__text-control {
    @extend %form-group-text-control;

    line-height: 26px !important;
    min-height: 120px;
    padding: 10px;
  }

  &__control_phone {
    &::placeholder {
      color: $color-black-7;
    }
  }

  &__label_small {
    font-size: $small-caption;
    text-transform: uppercase;
    margin-top: 0;
  }

  .mat-radio-group {
    margin-top: $inner-indent / 2;
  }

  .mat-radio-button {
    &:not(:last-child) {
      margin-right: $outer-indent;
    }

    font-weight: normal;
  }

  .mat-checkbox-inner-container {
    width: $checkbox-size;
    height: $checkbox-size;
  }

  .mat-checkbox-label {
    font-weight: normal;
  }

  .mat-checkbox-layout {
    align-items: center;
  }
}

.submit {
  width: $submit-width;
  margin-top: $inner-indent / 2;

  .mat-button-wrapper {
    text-transform: uppercase;
  }
}

.save-btn {
  width: 175px;
  margin-right: $inner-indent !important;

  .mat-button-wrapper {
    text-transform: uppercase;
  }
}

.totalfit-select {
  @extend %form-group;
  margin-top: 0;
  margin-bottom: 0;

  mat-select {
    @extend %form-group-control;
  }
}

// Todo refactor use %extends
.people-content {
  &__add {
    height: 16px;
  }

  &__save {
    width: 175px;
    height: 44px;
    margin-top: 8px;
  }

  &__comment {
    margin-top: 8px;
    text-transform: initial;

    h2 {
      margin: 0 0 8px 0 !important;
    }
  }

  .activity {
    max-width: 437px;
  }
}

.preview-information {
  &__general {
    display: flex;
    align-items: baseline;
    margin-bottom: $inner-indent;

    h2 {
      width: 145px;
      margin: 0 !important;
    }

    p {
      margin: 0;
      color: $color-black-4;
      // TODO Refactor after localization implementation
      text-transform: lowercase;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }

  &__list p {
    margin-bottom: $inner-indent / 2;
  }
}

.invalid-feedback {
  font-size: 12px;
  color: $color-red-5;
  line-height: 16px;
  position: absolute;
  bottom: -24px;
}


.add-entity-btn.mat-button {
  text-align: left;
  padding: 0;
  margin-top:-#{$inner-indent / 2};
}
