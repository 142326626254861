@import "variables";

.small-caption {
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
}

.caption {
  font-style: normal;
  font-size: 11px !important;
  line-height: 17px !important;
}

.second-text {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 17px;
  opacity: 0.5;
  color: $color-black-1;
}

.subtitle-1 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0041em;
}


.bold {
  font-weight: 500;
}
