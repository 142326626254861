$color-pallet-red: #D50000;
$color-pallet-orange: #F4511E;
$color-pallet-dark-green: #0B8043;
$color-pallet-light-green: #33B679;
$color-pallet-blue: #3F51B5;
$color-pallet-light-blue: #039BE5;
$color-pallet-puple: #907cff;
$color-pallet-grey: #7D7DA1;

// Black
$color-black-1: #000000;
$color-black-2: #212121;
$color-black-3: #3D3D3D;
$color-black-4: #4D4D4D;
$color-black-5: #666666;
$color-black-6: #7E8283;
$color-black-7: #acaeac;
$color-black-8: #D6D6D6;
$color-black-9: #D9D9D6;
$color-black-10: #f2f2f2;

// Red
$color-red-1: #991012;
$color-red-2: #B01315;
$color-red-3: #C71518;
$color-red-4: #E0171A;
$color-red-5: #EA3537;
$color-red-6: #E65C58;
$color-red-7: #EE585B;
$color-red-8: #FFA4A2;
$color-red-9: #FBD5D5;
$color-red-10: #FCE6E7;

// Purple
$color-purple-1: #3A3071;
$color-purple-2: #483C8D;
$color-purple-3: #5648A8;
$color-purple-4: #6B5DBA;
$color-purple-5: #907cff;
$color-purple-6: #A190FF;
$color-purple-7: #B1A3FF;
$color-purple-8: #C2B7FF;
$color-purple-9: #D2CAFF;
$color-purple-10: #F4F2FF;

// Green
$color-green-1: #07542C;
$color-green-2: #086635;
$color-green-3: #0A783F;
$color-green-4: #0D9C52;
$color-green-5: #10C065;
$color-green-6: #13E578;
$color-green-7: #31EE8C;
$color-green-8: #55F1A0;
$color-green-9: #8CF5BF;
$color-green-10: #C2FADD;

// Dark blue
$color-dark-blue-1: #0B1E88;
$color-dark-blue-2: #0E26AC;
$color-dark-blue-3: #112ED0;
$color-dark-blue-4: #1C3BED;
$color-dark-blue-5: #2E4BEE;
$color-dark-blue-6: #526AF1;
$color-dark-blue-7: #7789F4;
$color-dark-blue-8: #9BA9F7;
$color-dark-blue-9: #BFC8FA;
$color-dark-blue-10: #E3E7FD;


// Blue
$color-blue-1: #0C4275;
$color-blue-2: #105699;
$color-blue-3: #146ABD;
$color-blue-4: #177EE0;
$color-blue-5: #2388E8;
$color-blue-6: #58A5EE;
$color-blue-7: #8EC1F3;
$color-blue-8: #B1D5F7;
$color-blue-9: #D5E8FB;
$color-blue-10: #E6F2FC;

// Orange
$color-orange-1: #B73A13;
$color-orange-2: #C84015;
$color-orange-3: #DA4617;
$color-orange-4: #E74E1E;
$color-orange-5: #EB6A41;
$color-orange-6: #EF8665;
$color-orange-7: #F2A288;
$color-orange-8: #F6BEAC;
$color-orange-9: #FAD9CF;
$color-orange-10: #FCE7E1;

// Cyan
$color-cyan-1: #0A6462;
$color-cyan-2: #0E8785;
$color-cyan-3: #12ABA9;
$color-cyan-4: #16CECC;
$color-cyan-5: #23E8E5;
$color-cyan-6: #47ECE9;
$color-cyan-7: #6AEFED;
$color-cyan-8: #8EF3F2;
$color-cyan-9: #C3F9F8;
$color-cyan-10: #E6FCFC;

// Dark Green
$color-dark-green-1: #42750C;
$color-dark-green-2: #569910;
$color-dark-green-3: #6ABD14;
$color-dark-green-4: #7EE017;
$color-dark-green-5: #88E823;
$color-dark-green-6: #A5EE58;
$color-dark-green-7: #C1F38E;
$color-dark-green-8: #D5F7B1;
$color-dark-green-9: #E8FBD5;
$color-dark-green-10: #F1FCE6;

// Violet
$color-violet-1: #9300C7;
$color-violet-2: #B000EE;
$color-violet-3: #C216FF;
$color-violet-4: #CC3EFF;
$color-violet-5: #D765FF;
$color-violet-6: #E18CFF;
$color-violet-7: #EBB3FF;
$color-violet-8: #F0C7FF;
$color-violet-9: #F5DBFF;
$color-violet-10: #FBEEFF;

// Main colors
$color-yellow: #F3FF3C;
$color-white: #FFFFFF;
$color-button: #F5F5F5;

// Table colors
$table-lead: #ff0000;
$table-trial: #ff7a00;
$table-athlete: #01c868;
$table-team:  #907CFF;

// Drag & drop
$drag-and-drop: #C4C4C4;

//program preview week
$recovery-border: #00A355;
$recovery-background: #DAF7E8;
$workout-border:  #907cff;
$workout-background: #E8E7FF;
$rest-background: #4791FF;

// Calendar colors
$calendar-border-color: #D1D1D1;
$calendar-bcg: #E8E7FF;
