@mixin transition($property: all, $time: 0.1s, $linear: ease-out) {
  transition: $property $time $linear;
}

@mixin img() {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  object-fit: cover;
}
