@import 'variables';

$save-btn-width: 175px;

.mat-tab-header {
  border: none;
}

.mat-tab-label {
  border: none;
  font-weight: 500;
  font-size: $default-font-size;
  line-height: $inner-indent;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  padding: 0 24px !important;
  color: $color-black-6;
  margin: 0;
  min-width: fit-content !important;

  &-active {
    color: $color-black-1;
    opacity: 1 !important;
    font-weight: 500;
  }
}

.mat-tab-body-content {
  overflow: hidden !important;
}

.mat-button {
  text-transform: uppercase;
}

::ng-deep .mat-stepper-horizontal {
  @for $i from 1 through 4 {
    &.last-edited-step-#{$i} {
      @for $j from 1 through $i {
        .mat-stepper-horizontal-line:nth-of-type(#{$j}) {
          border-color: $color-purple-5;
        }

        .mat-step-header:nth-of-type(#{$j}) {
          .mat-step-icon {
            background-color: $color-purple-5;
          }
        }
      }
    }
  }

  .mat-step-header .mat-step-label {
    color: rgba(0, 0, 0, 0.87);
    position: absolute;
    top: 54px;
    left: 50%;
    transform: translateX(-50%);
  }

  .mat-step-header {
    padding: 0 !important;

    &:first-child {
      margin-left: 50px;
    }

    &:last-child {
      margin-right: 50px;
    }
  }

  .mat-stepper-horizontal-line {
    border-radius: $default_border-radius;
    border-top-width: 8px;
    border-color: $color-purple-10;
  }

  .mat-horizontal-stepper-header {
    overflow: inherit;
    padding: 0 62px;
    pointer-events: none;
  }

  .mat-step-icon  {
    background-color: $color-purple-10;
    height: 26px;
    width: 26px;
    margin-right: 0;

    &-selected {
      background-color: $color-purple-5;
    }

    .mat-step-icon-content {
      display: none;
    }
  }
}

::ng-deep .mat-tab-label-active {
  color: $color-black-1;
}

.profile-checkbox {
  ::ng-deep .mat-checkbox-label {
    width: $checkbox-label-width;
    white-space: normal;
    text-transform: none;
  }

  ::ng-deep .mat-checkbox-layout {
    align-items: end;
  }

  ::ng-deep .mat-checkbox-inner-container {
    width: $small-checkbox-size;
    height: $small-checkbox-size;
    margin: 2px $inner-indent 0 0;
    border: 1px solid $color-black-7;
  }

  ::ng-deep .mat-checkbox-background {
    background: $color-white;
  }

  ::ng-deep .mat-checkbox-checkmark-path {
    stroke: $table-team !important;
  }

  ::ng-deep .mat-divider {
    border-top-color: $color-black-9;
  }
}

.custom-toggle {
  .mat-slide-toggle-bar {
    height: 28px;
    width: 50px;
  }

  .mat-slide-toggle-thumb-container {
    height: 24px;
    width: 24px;
    top: 2px;
    left: 2px;
  }

  .mat-slide-toggle-thumb {
    height: 24px;
    width: 24px;
  }

  .mat-slide-toggle-bar {
    border-radius: 16px;
  }

  &.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(22px, 0, 0) !important;
  }
}

.mat-raised-button {
  box-shadow: none !important;
}
