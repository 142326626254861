// Global
$side-navigation-width: 250px;
$main-header-height: 70px;
$outer-indent: 24px;
$inner-indent: 16px;
$content-max-width: 1386px;
$logo-width: 130px;
$logo-height: 45px;

// Fonts
$default-font-size: 14px;
$small-font-size: 12px;
$default-icon-size: 24px;
$main-header-icon-size: 16px;
$input-font-size: 16px;
$checkbox-size: 20px;
$subtitle1-font-size: 16px;
$label-font-size: 16px;

$small-checkbox-size: 16px;

// Dropdowns
$dropdown-width: 240px;
$dropdown-width_s: 165px;
$small-caption: 10px;

// Grid
$grid-gap: 20px;
$card-item-gap: 4px;
$header-font-size: 20px;
$card-width: 228px;
$confirm-dialog-width: 340px;
$default_border-radius: 4px;

$subtitle1-font-size: 16px;
$label-font-size: 16px;
$input-height: 44px;

$text-area-width: 280px;
$tel-input-width: 320px;
$small-input-width: 144px;
$row-small-input: 94px;
$submit-width: 305px;
$form-control-prefix-width: 45px;

$extra-small-input-width: 94px;
$extra-small-input-width1: 138px;
$small-input-width: 146px;
$default-input-width: 230px;
$default-input-width-1: 290px;
$large-input-width: 340px;
$extra-large-input-width: 375px;

$coach-certificate-width: 170px;

//352
//282
//252
//149
//144

$full-dimensions: 100%;

$document-card-width: 228px;
$document-card-height: 210px;
$day-border-width: 3px;
$day-position-top: 38px;
$day-padding-left: 12px;
$day-rest-padding: 4px;
$day-line-height: 16px;
$checkbox-label-width: 380px;
$inactive-spinner-height: 180px
