%form-group {
  display: flex;
  flex-direction: column;
  margin: $inner-indent 0;
}

%form-group-label {
  font-size: $label-font-size;
  color: $color-black-6;
  margin-bottom: $inner-indent / 2;
  text-transform: initial;
}

%form-group-control {
  border: 1px solid $color-black-9;
  padding: 0 $outer-indent / 2;
  height: $input-height;
  line-height: $input-height;
  border-radius: $default_border-radius;

  font-size: $input-font-size;

  &:focus {
    border-color: $color-purple-5;
    outline: none;
  }
}

%form-group-text-control {
  @extend %form-group-control;

  //padding:0 $outer-indent / 2;
  height: unset;
  font-family: inherit;
}

%form-group-row {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;

  label:first-child {
    width: 100%;
  }

  .form-group {
    margin-top: 0;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-right: $grid-gap;
    }
  }

  &.expand .form-group {
    flex: 1;
  }
}
