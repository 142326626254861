/* You can add global styles to this file, and also import other style files */
html, body {
  margin: 0;
  padding: 0;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

body {
  line-height: 20px;
  color: #000;
  background-color: $color-white;
}

*, ::after, ::before {
  box-sizing: border-box;
  text-transform: initial;
}

main {
  overflow: auto;
  flex-grow: 1;
  background: $color-white;
  padding: $outer-indent;
}

main, totalfit-side-navigation {
  height: calc(100vh - #{$main-header-height});
  text-transform: capitalize !important;
}

.main-content {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: $content-max-width;
}

.custom-badge {
  border-radius: 50%;
  width: 8px;
  height: 8px;

  background-color: $color-purple-5;
}

.list {
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gap;
    margin-right: -$grid-gap;
  }

  &__row-item {
    margin-bottom: $outer-indent;
  }
}

.actions-header {
  display: flex;
  align-items: center;

  margin-bottom: $outer-indent;
  margin-top: -#{$outer-indent / 2};

  &--spread-elements {
    justify-content: space-between;
  }

  &__icon-actions {
    margin-right: auto;
  }

  button {
    .mat-button-wrapper {
      text-transform: uppercase;
    }

    &:not(:first-child) {
      margin-left: $outer-indent / 2;
    }
  }

  &__tabs {
    margin: 0 $outer-indent;
  }

  &_flex-end {
    justify-content: flex-end;
  }

  &_no-bottom-gap {
    margin-bottom: 0;
  }
}

.info-header {
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 40px;

  h2 {
    margin: 0 !important;
  }

  &__data {
    display: flex;
    column-gap: 35px;
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}

.totalfit-tooltip {
  width: 0;
  height: 0;
  @include transition(opacity);
  opacity: 0;
  position: fixed;
  z-index: 1102;

  &__body {
    background-color: $color-black-2;
    color: $color-white;
    font-size: 12px;
    line-height: 16px;
    padding: $inner-indent / 2 $inner-indent;
    border-radius: $default_border-radius;
    max-width: $outer-indent * 15;
    word-break: break-word;
    position: fixed;
  }

  &__triangle {
    position: fixed;
    width: 0;
    height: 0;
  }
}

.icon-trigger {
  min-width: 36px !important;
  height: 36px !important;
  padding: 0 !important;
}

.color-palette {
  &_red {
    i:before {
      color: $color-pallet-red !important;
    }

    color: $color-pallet-red !important;
    border-color: $color-pallet-red !important;
    background-color: $color-red-10 !important;

    &.card {
      color: inherit !important;
      border-color: inherit !important;
      background-color: inherit !important;
    }

    & .card__header {
      background-color: $color-pallet-red !important;
    }
  }

  &_red-8 {
    i:before {
      color: $color-red-8;
    }

    color: $color-red-8;
    border-color: $color-red-8;
    background-color: $color-red-8;

    &.card {
      color: inherit !important;
      border-color: inherit !important;
      background-color: inherit !important;
    }

    & .card__header {
      background-color: $color-red-8 !important;
    }
  }

  &_purple {
    i:before {
      color: $color-pallet-puple !important;
    }

    color: $color-pallet-puple !important;
    border-color: $color-pallet-puple !important;
    background-color: $color-purple-10 !important;

    &.card {
      color: inherit !important;
      border-color: inherit !important;
      background-color: inherit !important;
    }

    & .card__header {
      background-color: $color-pallet-puple !important;
    }
  }

  &_purple-6 {
    i:before {
      color: $color-purple-6;
    }

    color: $color-purple-6;
    border-color: $color-purple-6;
    background-color: $color-purple-6;

    &.card {
      color: inherit !important;
      border-color: inherit !important;
      background-color: inherit !important;
    }

    & .card__header {
      background-color: $color-purple-6 !important;
    }
  }

  &_green {
    i:before {
      color: $color-pallet-light-green !important;
    }

    color: $color-pallet-light-green !important;
    border-color: $color-pallet-light-green !important;
    background-color: $color-green-10 !important;

    &.card {
      color: inherit !important;
      border-color: inherit !important;
      background-color: inherit !important;
    }

    & .card__header {
      background-color: $color-pallet-light-green !important;
    }
  }

  &_dark-blue {
    i:before {
      color: $color-pallet-blue !important;
    }

    color: $color-pallet-blue !important;
    border-color: $color-pallet-blue !important;
    background-color: $color-dark-blue-10 !important;

    &.card {
      color: inherit !important;
      border-color: inherit !important;
      background-color: inherit !important;
    }

    & .card__header {
      background-color: $color-pallet-blue !important;
    }
  }

  &_dark-blue-8 {
    i:before {
      color: $color-dark-blue-8;
    }

    color: $color-dark-blue-8;
    border-color: $color-dark-blue-8;
    background-color: $color-dark-blue-8;

    &.card {
      color: inherit !important;
      border-color: inherit !important;
      background-color: inherit !important;
    }

    & .card__header {
      background-color: $color-dark-blue-8 !important;
    }
  }

  &_blue {
    i:before {
      color: $color-pallet-light-blue !important;
    }

    color: $color-pallet-light-blue !important;
    border-color: $color-pallet-light-blue !important;
    background-color: $color-blue-10 !important;

    &.card {
      color: inherit !important;
      border-color: inherit !important;
      background-color: inherit !important;
    }

    & .card__header {
      background-color: $color-pallet-light-blue !important;
    }
  }

  &_orange {
    i:before {
      color: $color-orange-5 !important;
    }

    color: $color-orange-5 !important;
    border-color: $color-orange-5 !important;
    background-color: $color-orange-10 !important;

    &.card {
      color: inherit !important;
      border-color: inherit !important;
      background-color: inherit !important;
    }

    & .card__header {
      background-color: $color-orange-5 !important;
    }
  }

  &_cyan {
    i:before {
      color: $color-cyan-5 !important;
    }

    color: $color-cyan-5 !important;
    border-color: $color-cyan-5 !important;
    background-color: $color-cyan-10 !important;

    &.card {
      color: inherit !important;
      border-color: inherit !important;
      background-color: inherit !important;
    }

    & .card__header {
      background-color: $color-cyan-5 !important;
    }
  }

  &_dark-green {
    i:before {
      color: $color-pallet-dark-green !important;
    }

    color: $color-pallet-dark-green !important;
    border-color: $color-pallet-dark-green !important;
    background-color: $color-dark-green-10 !important;

    &.card {
      color: inherit !important;
      border-color: inherit !important;
      background-color: inherit !important;
    }

    & .card__header {
      background-color: $color-pallet-dark-green !important;
    }
  }

  &_violet {
    i:before {
      color: $color-violet-5 !important;
    }

    color: $color-violet-5 !important;
    border-color: $color-violet-5 !important;
    background-color: $color-violet-10 !important;

    &.card {
      color: inherit !important;
      border-color: inherit !important;
      background-color: inherit !important;
    }

    & .card__header {
      background-color: $color-violet-5 !important;
    }
  }

  &_grey {
    i:before {
      color: $color-pallet-grey !important;
    }

    color: $color-pallet-grey !important;
    border-color: $color-pallet-grey !important;
    background-color: $color-pallet-grey !important;

    &.card {
      color: inherit !important;
      border-color: inherit !important;
      background-color: inherit !important;
    }

    & .card__header {
      background-color: $color-pallet-grey !important;
    }
  }
}

.icon-button {
  min-width: 30px !important;
  height: 30px;
  line-height: 30px !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;

  &__mute {
    background: $color-black-10;

    i {
      color: $color-black-7;
    }

    &:focus {
      outline: none;
      background: $color-black-9;

      i:before {
        color: $color-black-4;
      }
    }

    &:hover i:before {
      color: $color-black-4;
    }
  }
}

.inner-indent-m {
  margin: $inner-indent !important;

  &_left {
    margin-left: $inner-indent !important;
  }

  &_right {
    margin-right: $inner-indent !important;
  }

  &_top {
    margin-top: $inner-indent !important;
  }

  &_bottom {
    margin-bottom: $inner-indent !important;
  }
}

.inner-indent-m_2 {
  margin: $inner-indent / 2 !important;

  &_left {
    margin-left: $inner-indent / 2 !important;
  }

  &_right {
    margin-right: $inner-indent / 2 !important;
  }

  &_top {
    margin-top: $inner-indent / 2 !important;
  }

  &_bottom {
    margin-bottom: $inner-indent / 2 !important;
  }
}

.mr-auto {
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}


.dialog {
  width: $confirm-dialog-width;
  padding: 20px;
  border-radius: 4px;
  position: relative;

  &__close {
    position: absolute !important;
    top: $inner-indent / 2;
    right: $inner-indent / 2;
  }

  &__body {
    font-size: $default-font-size;
    font-weight: normal;
    margin-bottom: $outer-indent / 2;
  }

  &__header {
    margin-bottom: $inner-indent / 2;
    h3 {
      font-size: $subtitle1-font-size;
      font-weight: bold;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;

    button {
      min-width: 90px;

      .mat-button-wrapper {
        text-transform: uppercase;
      }

      &:not(:last-child) {
        margin-right: $inner-indent;
      }
    }
  }
}

.mat-dialog-container {
  padding: 0 !important;
}

.tell-selector {
  .mat-form-field-flex:hover {
    .mat-form-field-outline {
      color: $color-purple-5 !important;
    }
  }

  .mat-form-field-infix {
    border-top: 0!important;
    padding: 0 !important;

    min-height: $input-height;
    display: flex;
    align-items: center;
  }

  .mat-form-field-wrapper {
    padding: 0;
    margin: 0;
  }

  .country-selector {
    opacity: 1 !important;
    width: 100px !important;
    height: 40px !important;

    top: 55% !important;
    left: -12px !important;
    transform: translateY(-50%);

    .mat-button-wrapper {
      display: flex;
      width: calc(100% - 15px);
      justify-content: center;
    }

    &-flag {
      margin-right: 6px !important;
    }
  }

  input {
    display: inline-block;
    height: 34px;
    position: absolute !important;
    top: 54%;
    transform: translateY(-50%);
  }
}

.mat-menu-pane {
  max-height: 300px !important;
}


.cdk-overlay-container .default-select-width + * > .cdk-overlay-pane {
  width: 150px !important;
  margin-left: -120px;
}

.cdk-overlay-container .calendar-location-select-width + * > .cdk-overlay-pane {
  width: 180px !important;
}


button.close-array-item {
  position: absolute;
  top: 5px;
  right: -50px;
  min-width: 40px !important;
  padding: 0;
}

.color-palette {
  width: $small-input-width;
  background: $color-white;
  display: flex;
  flex-wrap: wrap;
  border-radius: $default_border-radius * 2;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25);


  .icon-trigger {
    i {
      font-size: 36px;
    }
    background-color: transparent !important;
  }
}

.content-block-wod {
  $notation-width: 250px;
  margin: $outer-indent 0;

  &__shadow-block {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12);
    transition: box-shadow 0.3s ease-in;
    padding: $inner-indent;
    margin-bottom: $inner-indent;
  }

  .icon-close {
    font-size: 12px;
  }

  p {
    display: inline-block;
    text-transform: initial;
    text-align: left;
    white-space: pre-wrap;
  }

  &__notation {
    max-width: $notation-width;
    width: 100%;
  }

  &__full-width {
    width: 100%;
  }

  &__text-area {
    max-width: $text-area-width;
    width: 100%
  }

  ::ng-deep {
    .mat-icon-button {
      width: 20px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;

      i {
        font-size: 12px;
        line-height: 21px;
        color: $color-black-4;
      }
    }
  }
}

.print-container__header {
  display: none;
}

.global-spinner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
}

.inactive-spinner {
  position: relative;
  display: block;
  left: unset;
  top: unset;
  right: unset;
  bottom: unset;
  z-index: 1;
  width: 100%;
  height: $inactive-spinner-height;

  figure::ng-deep {
    position: static;
    margin: $outer-indent auto 0;
  }
}

.empty-screen-with-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  margin-top: 70px;

  img {
    width: 60%;
    min-width: 475px;
  }

  button {
    margin-top: $outer-indent;

    .mat-button-wrapper {
      text-transform: uppercase;
    }
  }

  p {
    margin-top: $outer-indent;
    margin-bottom: 0;
    color: $color-black-6;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-transform: initial;
    width: 400px;
    text-align: center;
  }
}

.__PrivateStripeElement {
  width: 100%;
}

@media print {
  body * {
    visibility: hidden;
  }

  * { overflow: visible !important; }

  .print-container, .print-container * {
    visibility: visible;
  }

  .print-container {
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    width: 100vw;

    &__header {
      display: block;
      margin-bottom: 30px;
    }

    &__print-ignore {
      display: none !important;
    }
  }

  .mat-drawer-content {
    margin-left: 0 !important;
  }

  .mat-sidenav {
    display: none !important;
  }

  body, .print-container {
    height: auto !important;
  }
}

.library-context-nav + .cdk-overlay-connected-position-bounding-box .mat-menu-panel {
  padding: 0;
  min-height: 0;
}

.gap-left-auto {
  margin-left: auto !important;
}


.card-footer-container {
  &.footer-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
    padding-bottom: 12px;
  }

  .footer-action {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.0125em;
    width: calc(100% - 30px);
  }
}

.status-label {
  $this: &;
  $publish-color: $color-green-5;
  $pending-color: $color-orange-5;
  $draft-color: $color-black-5;
  $past-color: $color-black-8;
  $rejected : $color-red-5;

  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 $inner-indent;
  margin-bottom: 12px;

  &_published {
    color: $publish-color;

    #{$this}__mark {
      background-color: $publish-color;
    }
  }

  &_pending {
    color: $pending-color;

    #{$this}__mark {
      background-color: $pending-color;
    }
  }


  &_draft {
    color: $draft-color;

    #{$this}__mark {
      background-color: $draft-color;
    }
  }

  &_past {
    color: $past-color;

    #{$this}__mark {
      background-color: $past-color;
    }
  }

  &_rejected {
    color: $rejected;

    #{$this}__mark {
      background-color: $rejected;
    }
  }

  &__mark {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
  }
}


.close-container {
  position: relative;

  .icon-button {
    width: 38px;
    position: absolute !important;
    bottom: 7px;
    right: -51px;
  }
}


.training-header h2 {
  display: flex;
  justify-content: space-between;
}

.training {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  flex: 1;

  &__container {
    position: relative;
    z-index: 1;
    background: linear-gradient(96.83deg, rgba(33, 33, 33, 0.55) 0%, rgba(0, 0, 0, 0) 34.96%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.93) 100%)
  }

  &__instructors {
    display: flex;
    align-items: center;
    color: $color-white;
    padding: 24px;

    img {
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;

      &:not(:last-child) {
        margin-left: -16px;
      }
    }
  }

  &__instructor-images {
    display: flex;
    flex-direction: row-reverse;
    margin-right: $inner-indent / 2;

    img {
      background-color: $color-black-6;

      &.empty {
        padding: 5px;
      }
    }
  }

  .coma {
    margin-right: 4px;
    display: inline-block;
    &.last {
      display: none;
    }
  }

  &__type {
    padding: 5px 12px;
    border-radius: 4px;
    display: inline-block;

    &.general {
      background: $color-purple-5;
      color: $color-white;
    }

    &.defence {
      background: $color-red-7;
      color: $color-white;
    }

    &.youth {
      background: $color-green-5;
      color: $color-white;
    }

    &.performance {
      background: $color-orange-5;
      color: $color-white;
    }
  }

  &__content {
    margin-top: auto;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 24px;
  }

  &__image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;

    }
  }

  &__info {
    color: $color-white;
  }

  &__title {
    font-style: italic;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.015em;
    margin: #{$inner-indent / 2} 0;
  }

  &__subtitle {
    font-size: 14px;
    line-height: 19px;
    color: $color-black-7;
  }

  &__price {
    font-style: italic;
    font-weight: 900;
    font-size: 48px;
    line-height: 56px;
    color: $color-white;
  }
}

.host-partner {
  width: 30%;
  padding: 40px;
  text-transform: initial;
  &__info {
    h6 {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.0041em;
      margin-bottom: 6px;
    }
  }
}

.training-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.actions-list {
  margin-bottom: $inner-indent;
  margin-left: auto;

  button {
    display: inline-block;
  }

  button:not(:last-of-type){
    margin-right: $inner-indent / 2;
  }

  button:last-of-type {
    margin-left: $inner-indent / 2;
  }

  form.form-group {
    margin-left: auto !important;
    margin-top: $inner-indent !important;
  }
}

.table-block {
  width: 100%;
  max-width: 1170px;
  overflow: auto;
  position: relative;
  padding-bottom: 10px;
  height: calc(100vh - 202px);

  .icon-button {
    min-width: 25px !important;
    height: 25px;
    line-height: 25px;

    &__plus {
      opacity: 1 !important;
    }
  }

  table.mat-elevation-z8 {
    box-shadow: none;
    width: max-content;

    th.mat-header-cell {

      &:first-of-type {
        padding-left: 0;
      }

      text-align: left;
      color: $color-black-6;
      font-size: 12px;
      border: none;
      padding-right: $inner-indent / 2;
    }

    .mat-cell {
      border: none;
      padding: 10px 30px 10px 0;
      color: #4D4D4D;
      text-align: left;
      box-sizing: content-box;
      vertical-align: top;

      &__control {
        border: 1px solid $color-purple-5;
        border-radius: $default_border-radius;
        outline: none;
      }
    }

    tr.mat-header-row {
      height: auto;
    }

    .mat-header-row {
      height: 25px !important;
      line-height: 25px;
    }

    .text-ellipsis {
      display: table-cell;
    }

    .checkbox {

      .container {
        display: block;
        position: relative;
        padding-left: 16px;
        //  margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark {
        position: relative;
        display: inline-block;
        padding: 8px;
        height: 1px;
        width: 1px;
        border: 1px solid $color-black-7;
        border-radius: 2px;
      }

      .container:hover input ~ .checkmark {
        border: 1px solid $color-black-6;
      }

      .container input:checked ~ .checkmark {
        border: 1px solid $color-black-4;
      }

      .checkmark:after {
        content: "";
        position: absolute;
      }

      .container input:checked ~ .checkmark:after {
        display: block;
        left: 5px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid $color-purple-5;
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      .container .checkmark:after {
        border: solid $color-white;
      }
    }

    .filter {
      cursor: pointer;

      .filter-delete {
        padding: 4px;
        background-color: #F1EFFF;
        border-radius: 50%;
        display: none;
        align-items: center;
        justify-content: center;
        margin-left: 3px;
      }
    }

    .filter.active {
      svg {
        fill: $color-purple-5;
        stroke: $color-purple-5;
      }

      .filter-delete {
        display: inline-flex;
      }
    }

    .table-dropdown {
      cursor: pointer;

      .table-dropdown-icon {
        width: 12px;
        height: 12px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 4px;
        cursor: pointer;
      }
    }

    .table-dropdown:hover {
      color: $color-black-4;

      .table-dropdown-icon {
        .table-arrow__icon {
          path {
            fill: $color-black-4;
          }
        }
      }
    }

    .table-dropdown:active,
    .table-dropdown.active {
      color: $color-black-1;

      .table-dropdown-icon {
        background-color: $color-black-9;

        .table-arrow__icon {
          path {
            fill: $color-black-1;
          }
        }
      }
    }

    td {
      vertical-align: baseline;
      text-transform: none;

      &.comment-dell {
        display: -webkit-box;
        max-width: 312px;
        margin: 0 auto;
        -webkit-line-clamp: 3;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 0;
        margin-bottom: $inner-indent / 2;

        .comment-dell-wrap {
          display: -webkit-box;
          max-width: 312px;
          margin: 0 auto;
          -webkit-line-clamp: 3;
          /* autoprefixer: off */
          -webkit-box-orient: vertical;
          /* autoprefixer: on */
          overflow: hidden;
          text-overflow: ellipsis;
          padding-bottom: 0;
          margin-bottom: $inner-indent / 2;
          pointer-events: none;
          position: relative;
        }
      }
    }

    tr:not(.table-header) {
      &:hover, &.active {
        background-color: $color-purple-10;
      }
    }

    .table-comments,
    .table-address {
      max-width: 260px;
    }

    .table-sort {
      cursor: pointer;

      .table-sort-icon {
        width: 12px;
        height: 12px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 4px;
        cursor: pointer;
      }
    }

    .table-sort:hover {
      color: $color-black-4;

      .table-sort-icon {
        svg > path {
          stroke: $color-black-4;
        }
      }
    }

    .table-sort.active,
    .table-sort:active {
      color: $color-black-1;

      .table-sort-icon {
        svg > path {
          stroke: $color-black-1;
        }
      }
    }
  }
}
button.btn-outline {
  border-radius: 16px;
  color: $color-black-6;
  border-color: $color-black-6;
  margin-bottom: $inner-indent;
  width: 160px;
  padding: 0;

  &:hover {
    color:$color-purple-5;
    border-color: $color-purple-5;
  }

  &.active {
    background: $color-purple-10;
    color:$color-purple-5;
    border-color: $color-purple-5;
  }

  &:not(:nth-child(3n)) {
    margin-right: $inner-indent;
  }
}

.tab-container.with-search {
  width: calc(100% - 200px);
}

.page-control {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: $outer-indent / 2;
}

.search-box-container {
  display: flex;align-items: center;
  position: relative;
  .icon-search {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: $inner-indent / 2;

    &:before {
      color: $color-black-6;
    }
  }
}

.search-box {
  padding-left: 36px !important;
  background: $color-black-10;
  border-radius: $default_border-radius;
  border: none;
  max-width: 260px;
  height: 30px !important;
  font-size: 12px !important;
  margin-right: $outer-indent / 2;
}


$notation-width: 250px;
$text-area-width: 680px;

.workout-preview {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin-bottom: 0;
    }
  }

  &__header-actions {
    button {
      text-transform: uppercase;
    }

    .icon-button {
      margin-right: $inner-indent / 2;
      width: 37px;
      height: 37px;

      i:before {
        color: $color-black-6;
      }
    }
    button:not(.icon-button) {
      margin-left: $inner-indent / 2;
    }
  }

  &__body {
    margin-top: $inner-indent;
  }

  ::ng-deep {
    .mat-tab-label {
      padding: $outer-indent / 2 !important;
    }

    .mat-tab-header {
      margin: 0 -10px;
    }
  }
}

.shop {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
  padding: 0 $outer-indent;

  margin-right: -$outer-indent;
  margin-left: -$outer-indent;

  &__card {
    margin-bottom: $outer-indent;

    &_card-full {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      padding: 50px 40px;
    }

    width: calc(50% - 12px);
    min-width: 430px;
  }
}

.shop-card {
  position: relative;

  &__content-container {
    position: relative;
    padding: 20px;
  }

  &__large-separator {
    height: 115%;
    width: 4px;
    background-color:  $color-white;
    border-radius: $default_border-radius;
    margin: 0 $outer-indent 0 90px;
  }

  .equipment {
    padding-left: $outer-indent;
    margin-left: 95px;
    position: relative;

    &:before {
      content: '';
      height: 115%;
      width: 4px;
      border-radius: $default_border-radius;
      background: $color-white;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &__header {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: $color-white;
      margin-bottom: $inner-indent / 2;
    }

    &__body {
      opacity: 0.7;
    }
  }

  &__header {
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-white;
    margin-bottom: auto;
  }

  &__content {
    width: 45%;
  }

  &__equipment {
    width: 45%;
  }

  &__body {
    z-index: 1;
    color: $color-white;
    position: relative;
    margin-top: 95px;

    &_half {
      width: 50%;
    }

    &_card-full {
      display: flex;
      margin-top: 0;
    }
  }

  &__subtitle {
    &_opacity {
      opacity: 0.7;
    }
  }

  &__bought-statistic {
    margin-bottom: 6px;
    display: flex;
    align-items: center;

    .mat-icon {
      font-size: 14px;
      height: 14px;
      width: 14px;

      margin-right: $inner-indent / 2;
    }

    .label {
      padding: 2px #{$inner-indent / 2};
      background-color: $color-orange-4;
      border-radius: $default_border-radius;
      color: $color-white;
    }

    span:not(.label) {
      opacity: 0.7;
    }
  }

  &__bcg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $default-border-radius;
    object-fit: cover;
  }

  &__overlay {
    background: linear-gradient(78.15deg, rgba(0, 0, 0, 0.94) 9.35%, rgba(0, 0, 0, 0.14) 97.29%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0.8;
  }

  &__author {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      display: inline-block;
      margin-right: 8px;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.015em;
    text-transform: uppercase;
    font-style: italic;
    margin-bottom: 6px;
  }

  &__footer {
    padding-top: $inner-indent / 2;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .old-price {
      color: $color-black-8;
      font-size: 12px;
      line-height: 14px;
      text-decoration: line-through;
    }

    .price {
      font-weight: bold;
      font-size: 28px;
      line-height: 33px;
      color: $color-purple-5;

      &__purchased {
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        color: $color-black-6;

        display: flex;
        align-items: center;

        .mat-icon {
          color: $color-green-10;
          margin-right: $inner-indent / 2;
        }

        &_white {
          color: $color-white;
        }
      }
    }

    &_card-full {
      padding-left: 0;
      margin-top: $outer-indent;
      z-index: 1;
    }
  }

  &__action {
    &_card-full button {
      width: 200px;
    }

    button {
      width: 300px;
      height: 44px;

      .mat-button-wrapper {
        text-transform: uppercase;
      }
    }
  }
}

.shop-separator {
  width: 6px;
  height: 6px;

  border-radius: 50%;
  background-color: $color-white;
  display: inline-block;

  margin: 0 $inner-indent;
}

.view-control {
  display: flex;
  justify-content: space-between;

  i.active::before {
    color: $color-purple-5;
  }

  .icon-button {
    background-color: $color-black-10;

    &.active {
      background-color: #F1EFFF;
    }

    &:first-child {
      margin-right: $inner-indent / 2;
    }
  }
}

.drop-down-name {
  width: 300px;
  background: $color-white;
  border-radius: $default_border-radius;
  padding: $inner-indent;
  position: absolute;
  box-shadow: 0 3px 8px rgb(0 0 0 / 12%);
  font-family: 'Roboto', "Helvetica Neue", 'sans-serif';
  color: $color-black-6;
}


.last-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  &_in-popup {
    background: $color-white;
    width: 410px;
    height: 380px;
    justify-content: center;
    padding: 51px;
  }

  &__img {
    width: 100px;
    margin-bottom: 24px;
  }

  &__title {
    font-size: 34px;
    line-height: 40px;
    color: $color-black-3;
    margin-bottom: 12px;
    text-transform: initial;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 22px;
    color: $color-black-5;
    width: 300px;
    margin-bottom: 28px;
    text-align: center;
    text-transform: initial;
  }

  &_in-popup &__action {
    margin-top: auto;
  }

  &__action {
    width: 180px;
    height: 50px;

    .mat-button-wrapper {
      text-transform: uppercase;
    }

    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      align-self: center;
      text-decoration: none;
      color: white;
      line-height: 50px;
    }
  }
}

.mat-tab-label-content {
  text-transform: uppercase;
}

.toggle-title {
  display: flex;
  align-items: center;
  margin: $inner-indent 0;
  justify-content: space-between;
  column-gap: $inner-indent;
  height: 44px;

  h2 {
    margin-bottom: 0;
    line-height: 44px;
  }

  mat-slide-toggle {
    flex: 1;
  }

  totalfit-select {
    flex: 1;
    max-width: 50%;

    //.trigger {
    //  border: none;
    //  border-bottom: 1px solid $color-black-8;
    //}
  }

  & + .form-group > .form-group {
    margin-top: 0;
  }
}

.markers {
  display: flex;
  align-self: flex-end;

  &__circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #C4C4C4;
    position: relative;
    margin-right: 2px;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.active {
      border-color: #A1ABFF;
      color: #A1ABFF;
    }
  }
}

.workout-footer {
  display: flex;
  justify-content: space-between;
  padding: $inner-indent;

  &_flex-end {
    justify-content: flex-end;
  }
}

.workout-body {
  display: flex;
  flex-direction: column;

  .subtitle-1 {
    margin-bottom: 0;
  }
}

.workout-container {
  &_custom {
    margin-top: $inner-indent;
  }

  &__title {
    margin-top: $inner-indent / 2;
    margin-bottom: 0;
  }

  &__item {
    overflow: auto;
  }
}

.builder-workout-list {
  .content-card {
    height: 335px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .workout-footer {
      margin-top: -40px;
    }
  }
}

.pre-line {
  white-space: pre-line;
}

.week {
  display: flex;
  justify-content: space-between;
  margin-bottom: $inner-indent;

  .week-info {
    margin-top: $day-position-top  + $day-padding-left;
    margin-right: $small-caption - $day-padding-left/2;

    p {
      margin-bottom: $day-padding-left;
    }
  }

  .week-day {
    position: relative;
    margin-top: $day-position-top;
    margin-left: $day-padding-left/2;
    min-width: 150px;
    flex: 1;

    ::ng-deep.trigger .mat-button-wrapper {
      justify-content: flex-start !important;
    }

    .week-day__header {
      position: absolute;
      top: -$day-position-top;
      text-align: center;
      width: 100%;
    }

    .week-day__info {
      background-color: $color-black-10;
      border-radius: $default_border-radius/2;
      padding:  $day-padding-left  $inner-indent - 4 ;
      height: 100%;

      div {
        margin-bottom: 0;
        color: $color-black-6;

        p {
          color: inherit;
          max-width: calc( 100% - #{$outer-indent});
          margin-bottom: $day-padding-left;

        }

        &:hover {
          mat-icon {
            opacity: 1;
          }
        }
      }

      .week-day__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
          font-size: 16px;
        }
        .icon-add {
          color: $color-black-7;

          &:hover {
            color: $color-black-6;
          }

          &:active {
            color: $color-black-4;
          }
        }
      }
    }
  }

  .workout {
    .week-day__info {
      border-left: $day-border-width solid $workout-border;

      .week-day__footer {
        .icon-Workout-day {
          color: $color-black-6;
          font-size: $header-font-size;
        }
      }
    }
  }

  .workout.filled {
    .week-day__info {
      background-color: $workout-background;

      div {
        color: $workout-border;
      }

      .week-day__footer {
        .icon-Workout-day {
          color: $workout-border;
        }
      }
    }
  }

  .recovery {
    .week-day__info {
      border-left: $day-border-width solid $recovery-border;

      .week-day__footer {
        .icon-Recovery-day {
          color: $color-black-6;
        }
      }
    }
  }

  .recovery.filled {
    .week-day__info {
      background-color: $recovery-background;

      div {
        color: $recovery-border;
      }

      .week-day__footer {
        .icon-Recovery-day {
          color: $recovery-border;
        }
      }
    }
  }

  .rest {
    display: flex;
    align-items: flex-end;
    max-width: 40px;
    min-width: 24px;
    background-color: rgba($rest-background, 0.2);
    border-radius: $default_border-radius;

    .week-day__info {
      background-color: initial;
      padding-left: $day-rest-padding;
      padding-right: $day-rest-padding;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      p {
        position: absolute;
        top: $day-padding-left;
        left: 50%;
        transform-origin: 0 0;
        transform: rotate(90deg) translateY(-50%);
        color: $rest-background;
        width: max-content;
        margin-bottom: 0;
      }

      .week-day__footer {
        justify-content: center;
        width: 100%;
        height: $outer-indent;

        .icon-Rest-day {
          color:  $rest-background;
        }
      }
    }
  }
}
.week {
  &-info {
    margin-top: 10px !important;

    p {
      margin-bottom: 17px !important;
    }
  }

  &_table {
    display: table !important;
    width: 100%;
  }

  &-day {
    margin: 0 6px !important;
    height: 100%;

    &__info {
      padding-top: 0 !important;

      .disabled {
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    &__footer {
      margin-top: $inner-indent / 2;
    }

    &.filled {
      cursor: pointer;
    }

    &__actions {
      ::ng-deep {
        button {
          min-width: 35px;
          width: 35px;
          padding: 0;
          margin-right: -5px;
          height: 35px;
          line-height: 35px;
        }

        .mat-button-wrapper {
          justify-content: center !important;
        }
      }
    }
  }

  td {
    display: table-cell;
    vertical-align: top;
    height: 90px;
  }
}


.table-block.mat-style table {
  width: 100%;
  border-collapse:separate;
  border-spacing: 0 16px;

  tr:not(tr.cdk-header-row) {
    box-shadow: 0 4px 10px rgba(84, 94, 120, 0.15);
    border-radius: 4px;
  }

  td, th {
    &:not(&:first-child, &:last-child) {
      text-align: center;
    }
  }
  .mat-cell {
    vertical-align: middle;
    padding: 10px 30px;

    &:last-child {
      width: 26px;
    }
  }

  .mat-header-cell {
    padding-right: 0;
  }
}

.program-card {
  flex-basis: 300px;
  min-height: 200px;
  position: relative;

  &__title {
    margin-top: auto !important;

    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 26px !important;
    letter-spacing: 0.01em !important;
    text-shadow: 1px 1px 1px $color-black-1;
    color: $color-white;
  }

  &__info {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.01em;
    color: $color-white;
    display: flex;
    column-gap: 20px;
    text-shadow: 1px 1px 1px $color-black-1;
  }

  &__bcg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: contain;
  }

  &__body {
    padding: 10px;
    height: 100%;
    z-index: 1;
    position: relative;
    margin-top: auto;
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__chip {
    height: 24px;
    padding: 0 10px;
    line-height: 24px;
    background-color: #E3FF39;
    border-radius: 4px;

    font-weight: 400;
    font-size: 12px;
  }

  &--empty {
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $color-black-6;
    row-gap: 16px;
  }
}

.element-name__img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.text-align-left {
  text-align:left !important;
}

.side-draw {
  display: flex;
  flex-direction: column;

  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  opacity: 1;
  background: $color-white;
  width: 600px;
  height: 100vh;
  -webkit-animation-name: run;
  -webkit-animation-duration: 0.4s;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 30px;
    background-color: $color-black-9;
    width: 100%;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-black-4;
  }

  &__body {
    padding: 30px;
    flex: 1;
    overflow-y: auto;
  }

  &__action {
    width: 100%;
    background-color: $color-white;
    display: flex;
    justify-content: center;
    border-top: 1px solid $color-black-9;

    padding: 16px;
    column-gap: 16px;

    button {
      flex: 1;

      .mat-button-wrapper {
        text-transform: uppercase;
      }
    }
  }
}

.link-button {
  background-color: transparent;
  border: none;
  color: $color-purple-6;
  padding: 0;
  transition: all 0.3s ease-out;
  cursor: pointer;

  &:hover {
    color: $color-purple-4;
  }
}


.custom-notification-container {
  background-color: $color-white;
  color: $color-black-2;
  width: 408px;
  max-width: none !important;
  padding: 20px 16px !important;
  z-index: 9999;
}

.profile-edit-backdrop ~  .cdk-overlay-connected-position-bounding-box .mat-menu-panel {
  max-height: 300px;
}

.tabs {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  gap: 4px;


  border-bottom: 1px solid $color-black-9;
  padding: $outer-indent / 2;
  overflow-x: auto;

  &__item {
    background: $color-dark-blue-10;
    border-radius: 8px;
    padding: 4px 12px;
    white-space: nowrap;
  }
}

.password-visibility-toggler {
  position: absolute;
  top: 10px;
  right: 10px;
}

.highlight-span {
  background-color: $color-purple-9;
}
