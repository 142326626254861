$fonts-directory: '/assets/fonts';

@font-face {
  font-family: 'totalfit-icon-font';
  src:  url('#{$fonts-directory}/totalfit-icon-font_v.eot?xy1rkk');
  src:  url('#{$fonts-directory}/totalfit-icon-font_v.eot?xy1rkk#iefix') format('embedded-opentype'),
  url('#{$fonts-directory}/totalfit-icon-font_v.ttf?xy1rkk') format('truetype'),
  url('#{$fonts-directory}/totalfit-icon-font_v.woff?xy1rkk') format('woff'),
  url('#{$fonts-directory}/totalfit-icon-font_v.svg?xy1rkk#totalfit-icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'totalfit-icon-font' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: $default-icon-size;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "\e900";
}
.icon-Arrow:before {
  content: "\e901";
  color: #7e8283;
}
.icon-assigned:before {
  content: "\e902";
  color: #7e8283;
}
.icon-assigned-filled:before {
  content: "\e903";
}
.icon-assignment_turned_in-24px:before {
  content: "\e904";
}
.icon-assignment_turned_in-24px-2:before {
  content: "\e905";
  color: #7e8283;
}
.icon-assignment-24px:before {
  content: "\e906";
  color: #7e8283;
}
.icon-assignment-24px-2:before {
  content: "\e907";
}
.icon-Calendar-16:before {
  content: "\e908";
  color: #7e8283;
}
.icon-calendar:before {
  content: "\e909";
  color: #7e8283;
}
.icon-calendar-filled:before {
  content: "\e90a";
}
.icon-check:before {
  content: "\e90b";
}
.icon-check2:before {
  content: "\e90c";
  color: #907cff;
}
.icon-close:before {
  content: "\e90d";
}
.icon-documents:before {
  content: "\e90e";
  color: #7e8283;
}
.icon-documents-filled:before {
  content: "\e90f";
}
.icon-Download:before {
  content: "\e910";
  color: #7e8283;
}
.icon-edit:before {
  content: "\e911";
  color: #7e8283;
}
.icon-edit-16:before {
  content: "\e912";
}
.icon-edit-filled:before {
  content: "\e913";
}
.icon-edit-filled-20:before {
  content: "\e914";
}
.icon-Facebook:before {
  content: "\e915";
  color: #7e8283;
}
.icon-Favorite-field:before {
  content: "\e916";
}
.icon-Favorite-field-1:before {
  content: "\e917";
  color: #907cff;
}
.icon-File-copy:before {
  content: "\e918";
  color: #7e8283;
}
.icon-filter_list:before {
  content: "\e919";
}
.icon-Find-gym:before {
  content: "\e91a";
}
.icon-Find-gym-filled:before {
  content: "\e91b";
}
.icon-Folder:before {
  content: "\e91c";
  color: #a1abff;
}
.icon-Grid-view:before {
  content: "\e91d";
  color: #7e8283;
}
.icon-group-24px:before {
  content: "\e91e";
  color: #7e8283;
}
.icon-group-24px-2:before {
  content: "\e91f";
}
.icon-icon-back:before {
  content: "\e920";
}
.icon-Illustrator .path1:before {
  content: "\e921";
  color: rgb(255, 193, 7);
}
.icon-Illustrator .path2:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(93, 64, 55);
}
.icon-Illustrator .path3:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(255, 193, 7);
}
.icon-Illustrator .path4:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(255, 193, 7);
}
.icon-Illustrator .path5:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(255, 193, 7);
}
.icon-Illustrator .path6:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(255, 193, 7);
}
.icon-Instagram:before {
  content: "\e927";
  color: #7e8283;
}
.icon-Linkedin:before {
  content: "\e928";
  color: #7e8283;
}
.icon-List-view:before {
  content: "\e929";
  color: #7e8283;
}
.icon-local_printshop:before {
  content: "\e92a";
}
.icon-local_printshop-filled:before {
  content: "\e92b";
}
.icon-message:before {
  content: "\e92c";
  color: #7e8283;
}
.icon-message-filled:before {
  content: "\e92d";
}
.icon-Microsoft-excel .path1:before {
  content: "\e92e";
  color: rgb(0, 163, 85);
}
.icon-Microsoft-excel .path2:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(0, 163, 85);
}
.icon-Microsoft-excel .path3:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(250, 250, 250);
}
.icon-Microsoft-excel .path4:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(250, 250, 250);
}
.icon-Microsoft-excel .path5:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(0, 163, 85);
}
.icon-Microsoft-excel .path6:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(0, 163, 85);
}
.icon-Microsoft-excel .path7:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(0, 163, 85);
}
.icon-Microsoft-excel .path8:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(0, 163, 85);
}
.icon-Microsoft-excel .path9:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(0, 163, 85);
}
.icon-Microsoft-excel .path10:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(0, 163, 85);
}
.icon-Microsoft-powerpoint .path1:before {
  content: "\e938";
  color: rgb(255, 87, 34);
}
.icon-Microsoft-powerpoint .path2:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(255, 87, 34);
}
.icon-Microsoft-powerpoint .path3:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(255, 87, 34);
}
.icon-Microsoft-powerpoint .path4:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(255, 87, 34);
}
.icon-Microsoft-powerpoint .path5:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(255, 87, 34);
}
.icon-Microsoft-powerpoint .path6:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(255, 87, 34);
}
.icon-Microsoft-powerpoint .path7:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(250, 250, 250);
}
.icon-Microsoft-word .path1:before {
  content: "\e93f";
  color: rgb(21, 101, 192);
}
.icon-Microsoft-word .path2:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}
.icon-Microsoft-word .path3:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(250, 250, 250);
}
.icon-Microsoft-word .path4:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}
.icon-Microsoft-word .path5:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}
.icon-Microsoft-word .path6:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}
.icon-Microsoft-word .path7:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}
.icon-Microsoft-word .path8:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}
.icon-Move:before {
  content: "\e947";
  color: #d6d6d6;
}
.icon-None:before {
  content: "\e948";
  color: #7e8283;
}
.icon-notifications:before {
  content: "\e949";
}
.icon-notifications-filled:before {
  content: "\e94a";
}
.icon-Password:before {
  content: "\e94b";
  color: #7e8283;
}
.icon-Pdf .path1:before {
  content: "\e94c";
  color: rgb(238, 88, 91);
}
.icon-Pdf .path2:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Pdf .path3:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Pdf .path4:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Photoshop .path1:before {
  content: "\e950";
  color: rgb(144, 202, 249);
}
.icon-Photoshop .path2:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(40, 53, 147);
}
.icon-Photoshop .path3:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(144, 202, 249);
}
.icon-Photoshop .path4:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(144, 202, 249);
}
.icon-Picture .path1:before {
  content: "\e954";
  color: rgb(230, 92, 88);
}
.icon-Picture .path2:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-point:before {
  content: "\e956";
  color: #907cff;
}
.icon-point_2:before {
  content: "\e957";
}
.icon-Progress:before {
  content: "\e958";
}
.icon-Progress-filled:before {
  content: "\e959";
}
.icon-Recovery-day:before {
  content: "\e95a";
}
.icon-Rest-day:before {
  content: "\e95b";
}
.icon-schedule-24px-1:before {
  content: "\e95c";
}
.icon-search:before {
  content: "\e95d";
}
.icon-Share:before {
  content: "\e95e";
  color: #7e8283;
}
.icon-Share-field:before {
  content: "\e95f";
  color: #7e8283;
}
.icon-Trash:before {
  content: "\e960";
  color: #7e8283;
}
.icon-Twitter:before {
  content: "\e961";
  color: #7e8283;
}
.icon-Video:before {
  content: "\e962";
  color: #e65c58;
}
.icon-Workout-day:before {
  content: "\e963";
}
.icon-Zip-rar .path1:before {
  content: "\e964";
  color: rgb(255, 185, 0);
}
.icon-Zip-rar .path2:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(255, 213, 91);
}
.icon-Zip-rar .path3:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(191, 87, 18);
}
.icon-Zip-rar .path4:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(191, 87, 18);
}
.icon-Zip-rar .path5:before {
  content: "\e968";
  margin-left: -1em;
  color: none;
}
